
import { ref, watchEffect, defineComponent } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { roles } from '@/helpers/constants.ts'
import { Patient } from '@/types.ts'
import { simpleDate } from '@/helpers/date'

export default defineComponent({
  components: { Button, InputText, Calendar, BaseValidationErrors },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Save Patient'
    },
    patient: {
      type: Object as () => Patient,
      default: () => {
        return {}
      }
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      address: '',
      comments: '',
      dob: new Date(),
      email: '',
      firstName: '',
      lastName: '',
      mobile: ''
    })

    const rules = {
      formData: {
        dob: { required },
        email: { required },
        firstName: { required },
        lastName: { required },
        mobile: { required }
      }
    }

    const fields = [
      { name: 'firstName', label: 'First Name', rules: 'required' },
      { name: 'lastName', label: 'Last Name', rules: 'required' },
      { name: 'dob', label: 'Date of Birth', rules: 'required' },
      { name: 'email', label: 'Email', rules: 'required' },
      { name: 'mobile', label: 'Phone Number', rules: 'required' },
      { name: 'address', label: 'Address', rules: null },
      { name: 'comments', label: 'Comments', rules: null }
    ]

    const validation = useVuelidate(rules, { formData })

    watchEffect(() => {
      if (Object.keys(props.patient).length) {
        const {
          address,
          comments,
          dob,
          email,
          firstName,
          lastName,
          mobile
        } = props.patient

        formData.value = {
          address,
          comments,
          dob: new Date(dob),
          email,
          firstName,
          lastName,
          mobile
        }
      }
    })

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        const dob = simpleDate(formData.value.dob)
        emit('submitted', { ...formData.value, dob })
      }
    }

    const thisYear = new Date().getFullYear()

    return { formData, roles, validation, submit, fields, thisYear }
  }
})
